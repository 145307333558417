"use client";
import { SignIn, useUser } from "@clerk/nextjs";
import { Box } from "@mui/material";
import { Inter } from "next/font/google";
import posthog from "posthog-js";
import { useEffect } from "react";
import { useDarkMode } from "~/context/DarkmodeContext";
import { pusherClient } from "~/services/third-party/pusher";
import { generateClerkTheme } from "~/styles/theme";

const inter = Inter({
  subsets: ["latin"],
});

export default function Page() {
  const { mode } = useDarkMode();
  const { user } = useUser();

  useEffect(() => {
    void user?.reload();
    posthog.reset();
    pusherClient.unsubscribe(`private-${user?.id}`);
  }, [user?.id, user?.reload]);

  return (
    <Box
      className={inter.className}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <SignIn appearance={generateClerkTheme(mode)} />
    </Box>
  );
}
